import * as React from 'react';
import { ContractService } from '../services/ContractService';

export enum MintStatus {
    PAUSED,
    FREE,
    PAID
}

export interface ContractMethods {
    maxPerWallet: number | undefined;
    maxFreePerWallet: number | undefined;
    totalSupply: number | undefined;
    maxSupply: number | undefined;
    maxFreeSupply: number | undefined;
    soldOut: boolean | undefined;
    freeSoldOut: boolean | undefined;
    price: number | undefined;
    mintStatus: MintStatus;
    mint: (quantity: number, account: string) => Promise<void>;
    freeMint: (quantity: number, account: string) => Promise<void>;
}

const ContractContext = React.createContext<ContractMethods>({
    maxPerWallet: undefined,
    maxFreePerWallet: undefined,
    totalSupply: undefined,
    maxSupply: undefined,
    maxFreeSupply: undefined,
    soldOut: undefined,
    freeSoldOut: undefined,
    price: undefined,
    mintStatus: MintStatus.PAUSED,
    mint: async () => { },
    freeMint: async () => { }
});

export const ContractProvider = (props: { children: React.ReactNode }) => {
    const [maxPerWallet, setMaxPerWallet] = React.useState<number | undefined>(undefined);
    const [maxFreePerWallet, setMaxFreePerWallet] = React.useState<number | undefined>(undefined);
    const [totalSupply, setTotalSupply] = React.useState<number | undefined>(undefined);
    const [maxSupply, setMaxSupply] = React.useState<number | undefined>(undefined);
    const [maxFreeSupply, setMaxFreeSupply] = React.useState<number | undefined>(undefined);
    const [soldOut, setSoldOut] = React.useState<boolean | undefined>(undefined);
    const [freeSoldOut, setFreeSoldOut] = React.useState<boolean | undefined>(undefined);
    const [price, setPrice] = React.useState<number | undefined>(undefined);
    const [mintStatus, setMintStatus] = React.useState<MintStatus>(MintStatus.PAUSED);

    const refreshCallback = React.useCallback(() => {
        const service = new ContractService();

        service.getMaxPerWallet().then(setMaxPerWallet);
        service.getMaxFreePerWallet().then(setMaxFreePerWallet);
        service.getTotalSupply().then(setTotalSupply);
        service.getMaxSupply().then(setMaxSupply);
        service.getMaxFreeSupply().then(setMaxFreeSupply);
        service.isSoldOut().then(setSoldOut);
        service.isFreeSoldOut().then(setFreeSoldOut);
        service.getPrice().then(setPrice);
        service.mintStatus().then(mintStatusIndex => {
            if (mintStatusIndex == 0) { setMintStatus(MintStatus.PAUSED); }
            else if (mintStatusIndex == 1) { setMintStatus(MintStatus.FREE); }
            else if (mintStatusIndex == 2) { setMintStatus(MintStatus.PAID); }
            else { setMintStatus(MintStatus.PAUSED); }
        });
    }, []);

    React.useEffect(() => {
        refreshCallback();
        setInterval(() => refreshCallback(), 1000);
    }, [refreshCallback]);

    const mint = async (quantity: number, account: string) => {
        await new ContractService().mint(quantity, account);
    };

    const freeMint = async (quantity: number, account: string) => {
        await new ContractService().freeMint(quantity, account);
    };

    return (
        <ContractContext.Provider value={{ maxPerWallet, maxFreePerWallet, totalSupply, maxSupply, maxFreeSupply, soldOut, freeSoldOut, price, mintStatus, mint, freeMint }}>
            {props.children}
        </ContractContext.Provider>
    );
};

export function useContract(): ContractMethods {
    return React.useContext(ContractContext);
}