import { useMetaMask, MetaMaskMethods } from '../../providers/MetaMaskProvider';

export function WalletConnectButton() {
    const metamask = useMetaMask();

    const onClick = () => {
        if (metamask.active) {
            metamask.disconnect();
        } else {
            metamask.connect();
        }
    };

    const animation = metamask.account ? "" : "";

    return (
        <button
            className={`transition ease-in-out uppercase rounded-md px-4 py-2 border-2 border-accent text-xl text-white hover:text-accent bg-accent hover:bg-transparent disabled:text-secondary disabled:border-secondary ${animation}`}
            onClick={onClick}
            disabled={metamask.loading}
        >
            {caption(metamask)}
        </button>
    );
}

function caption(metamask: MetaMaskMethods) {
    if (metamask.active && metamask.account) {
        const start = metamask.account.substring(0, 5);
        const end = metamask.account.substring(metamask.account.length - 4, metamask.account.length);
        return `${start}...${end}`;
    } else if (metamask.loading) {
        return "connecting ...";
    } else {
        return "connect wallet";
    }
}