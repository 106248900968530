import { OpenSeaIcon } from './OpenSeaIcon';
import { EtherscanIcon } from './EtherscanIcon';
import { TwitterIcon } from './TwitterIcon';

export function Header() {
    return (
        <div className="flex justify-center flex-row">
            <TwitterIcon />
            <OpenSeaIcon />
            <EtherscanIcon />
        </div>
    );
}