import './index.css';
import App from './App';
import Web3 from 'web3';
import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { MetaMaskProvider } from './providers/MetaMaskProvider';
import { ContractProvider } from './providers/ContractProvider';

console.log("What are you looking for? :)");

function getLibrary(provider: any, connector: any) {
  return new Web3(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetaMaskProvider>
        <ContractProvider>
          <App />
        </ContractProvider>
      </MetaMaskProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);