import Web3 from 'web3';
import env from '../env.json';
import Abi from '../contract.json';

export class ContractService {
    getMaxPerWallet(): Promise<number> {
        return this.contract().methods.maxNFTPerWallet().call();
    }

    getMaxFreePerWallet(): Promise<number> {
        return this.contract().methods.maxFreeNFTPerWallet().call();
    }

    getTotalSupply(): Promise<number> {
        return this.contract().methods.totalSupply().call();
    }

    getMaxSupply(): Promise<number> {
        return this.contract().methods.MAX_SUPPLY().call();
    }

    getMaxFreeSupply(): Promise<number> {
        return this.contract().methods.FREE_SUPPLY().call();
    }

    isSoldOut(): Promise<boolean> {
        return this.contract().methods.soldOut().call();
    }

    isFreeSoldOut(): Promise<boolean> {
        return this.contract().methods.freeSoldOut().call();
    }

    getNumberMinted(account: string): Promise<number> {
        return this.contract().methods.numberMinted(account).call();
    }

    getNumberFreeMinted(account: string): Promise<number> {
        return this.contract().methods.numberFreeMinted(account).call();
    }

    getPrice(): Promise<number> {
        return this.contract().methods.paid_price().call();
    }

    mintStatus(): Promise<number> {
        return this.contract().methods.mintStatus().call();
    }

    async mint(quantity: number, account: string): Promise<void> {
        const price = await this.getPrice();
        const mintParams = { from: account, value: price * quantity };
        const mint = this.contract().methods.mint(quantity);
        const gasEstimate = await mint.estimateGas(mintParams);

        await mint.send({
            ...mintParams,
            gas: gasEstimate
        });
    }

    async freeMint(quantity: number, account: string): Promise<void> {
        const mintParams = { from: account };
        const mint = this.contract().methods.freeMint(quantity);
        const gasEstimate = await mint.estimateGas(mintParams);

        await mint.send({
            ...mintParams,
            gas: gasEstimate
        });
    }

    contract() {
        const abi: any = Abi;
        const web3 = new Web3(Web3.givenProvider);
        return new web3.eth.Contract(abi, env.contract_address);
    }

    web3(): Web3 {
        return new Web3(Web3.givenProvider);
    }
}