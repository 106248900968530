import React from 'react';
import toast from 'react-hot-toast';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useContract } from '../../providers/ContractProvider';
import { useMetaMask } from '../../providers/MetaMaskProvider';
import { ContractService } from '../../services/ContractService';

export function PaidMint() {
    const metamask = useMetaMask();
    const contract = useContract();

    if (contract.soldOut) {
        return <h2 className="text-primary uppercase text-2xl mt-10 mb-10 text-center">Sold out</h2>;
    } else if (!metamask.account) {
        return <h2 className="text-primary uppercase text-2xl mt-10 mb-10 text-center animate-pulse">Minting now</h2>;
    }

    return <MintSelection />;
}

function MintSelection() {
    const metamask = useMetaMask();
    const contract = useContract();
    const [quantity, setQuantity] = React.useState(1);
    const [minting, setMinting] = React.useState(false);
    const [numberMinted, setNumberMinted] = React.useState<number | undefined>(undefined);

    const mintsLeft = Math.min((contract.maxPerWallet || 0) - (numberMinted || 0), (contract.maxSupply || 0) - (contract.totalSupply || 0));

    const price = quantity * (contract.price || 0) / 1000000000000000000;
    const canDecrease = quantity > 1;
    const canIncrease = quantity < mintsLeft;

    const refreshCallback = React.useCallback(() => {
        if (!metamask.account) { return; }

        new ContractService().getNumberMinted(metamask.account || '').then(setNumberMinted);
    }, [metamask.account]);

    React.useEffect(() => {
        refreshCallback();
        setInterval(() => refreshCallback(), 5000);
    }, [refreshCallback]);

    const mint = async () => {
        if (minting) { return; }
        setMinting(true);
        try {
            await toast.promise(contract.mint(quantity, metamask.account || ''), {
                loading: 'Minting...',
                success: 'Minting completed',
                error: (err) => err.message ? `${err.message}` : `${err}`
            });
        } finally {
            setMinting(false);
            setQuantity(1);
        }
    };

    if (mintsLeft <= 0) {
        return <h2 className="text-primary uppercase text-4xl mt-10 mb-10 text-center">Max minted</h2>;
    }

    return (
        <div className="flex flex-col justify-center mt-10 mb-10 space-y-4 max-w-md m-auto">
            <h2 className="text-primary uppercase text-4xl text-center">{price} ETH</h2>
            <div className="flex justify-between space-x-10 mx-auto">
                <button className="m-1" onClick={() => setQuantity(quantity - 1)} disabled={!canDecrease}>
                    <FaMinusCircle size={40} className="transition ease-in-out text-primary opacity-100 hover:opacity-70" />
                </button>
                <h2 className="text-primary uppercase text-4xl text-center">{quantity}</h2>
                <button className="m-1" onClick={() => setQuantity(quantity + 1)} disabled={!canIncrease}>
                    <FaPlusCircle size={40} className="transition ease-in-out text-primary opacity-100 hover:opacity-70" />
                </button>
            </div>
            <div className="flex justify-center">
                {!minting &&
                    <button className="uppercase rounded-md px-4 py-2 border-2 text-xl text-white bg-accent border-accent hover:bg-transparent hover:text-accent" onClick={mint} disabled={minting}>
                        mint
                    </button>
                }
            </div>
        </div>
    );
}