import env from '../../env.json';
import { FaTwitter } from 'react-icons/fa';

export function TwitterIcon() {
    if (env.twitter_url === "") { return null; }
    return (
        <a target="_blank" href={env.twitter_url} className="transition ease-in-out p-1 m-1 opacity-70 hover:opacity-100">
            <FaTwitter size={31} className="text-white" />
        </a>
    );
}