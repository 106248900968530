import React from 'react';
import { useMetaMask } from '../../providers/MetaMaskProvider';
import { ContractService } from '../../services/ContractService';
import { MintStatus, useContract } from '../../providers/ContractProvider';

export function MintStats() {
    const metamask = useMetaMask();
    const contract = useContract();
    const mintStatus = contract.mintStatus;
    const [numberMinted, setNumberMinted] = React.useState<number | undefined>(undefined);
    const [numberFreeMinted, setNumberFreeMinted] = React.useState<number | undefined>(undefined);

    const refreshCallback = React.useCallback(() => {
        if (!metamask.account) { return; }

        new ContractService().getNumberMinted(metamask.account || '').then(setNumberMinted);
        new ContractService().getNumberFreeMinted(metamask.account || '').then(setNumberFreeMinted);
    }, [metamask.account]);

    React.useEffect(() => {
        refreshCallback();
        setInterval(() => refreshCallback(), 1000);
    }, [refreshCallback]);

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-4 md:gap-0">
                <p className="text-secondary">Mint Status</p>
                <div className="text-secondary flex space-x-2 justify-end">
                    <p>{MintStatus[mintStatus].toLocaleLowerCase()}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 md:gap-0">
                <p className="text-secondary">Free Supply</p>
                <div className="text-secondary flex space-x-2 justify-end">
                    <p>{Math.min(contract.totalSupply || 0, contract.maxFreeSupply || 0)}</p>
                    <p>/</p>
                    <p>{contract.maxFreeSupply || '0'}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 md:gap-0">
                <p className="text-secondary">Total Supply</p>
                <div className="text-secondary flex space-x-2 justify-end">
                    <p>{contract.totalSupply || '0'}</p>
                    <p>/</p>
                    <p>{contract.maxSupply || '0'}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 md:gap-0">
                <p className="text-secondary">Free Minted</p>
                <div className="text-secondary flex space-x-2 justify-end">
                    <p>{numberFreeMinted || '0'}</p>
                    <p>/</p>
                    <p>{contract.maxFreePerWallet || '0'}</p>
                </div>
            </div>
            {mintStatus === MintStatus.PAID &&
                <div className="grid grid-cols-2 gap-4 md:gap-0">
                    <p className="text-secondary">Paid Minted</p>
                    <div className="text-secondary flex space-x-2 justify-end">
                        <p>{numberMinted || '0'}</p>
                        <p>/</p>
                        <p>{contract.maxPerWallet || '0'}</p>
                    </div>
                </div>
            }
        </div>
    );
}