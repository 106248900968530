import env from '../env.json';
import * as React from 'react';
import toast from 'react-hot-toast';
import { isMobile } from 'react-device-detect';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from "@web3-react/injected-connector";

const injected = new InjectedConnector({ supportedChainIds: [env.chain] });

export interface MetaMaskMethods {
    loading: boolean;
    active: boolean;
    account: string | null | undefined;
    connect: () => void;
    disconnect: () => void;
}

const MetaMaskContext = React.createContext<MetaMaskMethods>({
    loading: false,
    active: false,
    account: undefined,
    connect: () => { },
    disconnect: () => { }
});

export const MetaMaskProvider = (props: { children: React.ReactNode }) => {
    const { activate, account: _account, active: _active, deactivate } = useWeb3React();
    const [loading, setLoading] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [account, setAccount] = React.useState<string | null | undefined>(undefined);

    const connect = async () => {
        setLoading(true);
        try {
            await toast.promise(activate(injected, undefined, true), {
                loading: 'Connecting to MetaMask...',
                success: 'Connected!',
                error: (err) => humanizeError(err)
            });
        } catch (e) {
            if (isMobile) {
                window.open(`https://metamask.app.link/dapp/${env.website}/`, "_blank");
            }
        } finally {
            setLoading(false);
        }
    };

    const disconnect = async () => {
        deactivate();
    };

    const activeCallback = React.useCallback(() => {
        setActive(_active);
    }, [_active]);

    const accountCallback = React.useCallback(() => {
        setAccount(_account);
    }, [_account]);

    React.useEffect(() => {
        activeCallback();
    }, [activeCallback]);

    React.useEffect(() => {
        accountCallback();
    }, [accountCallback]);

    return (
        <MetaMaskContext.Provider value={{ loading, active, account, connect, disconnect }}>
            {props.children}
        </MetaMaskContext.Provider>
    );
};

export function useMetaMask(): MetaMaskMethods {
    return React.useContext(MetaMaskContext);
}

function humanizeError(e: any): string {
    if (e instanceof Error) {
        if (e.message.includes('No Ethereum provider was found')) {
            return "Please install MetaMask!";
        } else {
            return e.message;
        }
    }
    return `${e}`;
}