import { FreeMint } from "./FreeMint";
import { PaidMint } from "./PaidMint";
import { MintStats } from "./MintStats";
import icon from '../../images/bird.gif';
import { MintStatus, useContract } from '../../providers/ContractProvider';

export function Mint() {
    const contract = useContract();
    const mintStatus = contract.mintStatus;

    return (
        <div className="">
            <div className="hidden md:grid grid-cols-2 space-x-5">
                <img src={icon} width={300} className="rounded-md" />
                <div className="flex flex-col">
                    <MintStats />
                    {mintStatus === MintStatus.PAUSED && <h2 className="text-primary uppercase text-2xl mt-10 mb-10 text-center animate-pulse">Mint paused</h2>}
                    {mintStatus === MintStatus.FREE && <FreeMint />}
                    {mintStatus === MintStatus.PAID && <PaidMint />}
                </div>
            </div>
            <div className="flex flex-col md:hidden space-y-5 justify-center">
                <img src={icon} className="rounded-md" />
                <MintStats />
                {mintStatus === MintStatus.PAUSED && <h2 className="text-primary uppercase text-2xl mt-10 mb-10 text-center animate-pulse">Mint paused</h2>}
                {mintStatus === MintStatus.FREE && <FreeMint />}
                {mintStatus === MintStatus.PAID && <PaidMint />}
            </div>
        </div>
    );
}