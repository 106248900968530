import { Mint } from "./components/mint/Mint";
import { Intro } from "./components/intro/Intro";
import { Header } from "./components/header/Header";
import { Toaster, DefaultToastOptions } from 'react-hot-toast';
import { WalletConnectButton } from "./components/mint/WalletConnectButton";

const toastOptions: DefaultToastOptions = {
  success: {
    style: {
      background: 'var(--color-success)',
      color: 'var(--color-success-contrast)'
    },
    iconTheme: {
      primary: 'var(--color-success-contrast)',
      secondary: 'var(--color-success)'
    }
  },
  error: {
    style: {
      background: 'var(--color-error)',
      color: 'var(--color-error-contrast)'
    },
    iconTheme: {
      primary: 'var(--color-error-contrast)',
      secondary: 'var(--color-error)'
    }
  }
};

export default function App() {
  return (
    <div className="flex-1">
      <div className="flex-1 p-5 md:p-10 space-y-5">
        <Header />
        <div className="max-w-xl m-auto w-2/12 pl-8 pr-8">
          <Intro />
        </div>
        <div className="max-w-xl m-auto p-8 bg-secondary rounded-xl space-y-5">
          <Mint />
          <div className="flex justify-center">
            <WalletConnectButton />
          </div>
        </div>
      </div>
      <Toaster toastOptions={toastOptions} />
    </div >
  );
}